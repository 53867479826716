<template>
  <div class="machine-list">
    <!--    <a-alert v-if="cluster.deployEnvType === 'ALIBABA_CLOUD_HOSTED'" banner-->
    <!--             closable-->
    <!--             message="操作提示：机器添加后须在机器列表，点击【安装】按钮安装客户端。" style="margin-bottom: 10px;" type="warning"/>-->
    <Breadcrumb style="margin-bottom: 10px;">
      <BreadcrumbItem to="/dmmachine">{{ $t('cha-xun-ji-qi') }}
      </BreadcrumbItem>
      <BreadcrumbItem>{{ $t('ji-qi-lie-biao') }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="info">
      <div class="top">
        <a-input v-model="queryForm.text" class="text" :placeholder="$t('qing-shu-ru-ji-qi-id-ming-cheng-cha-xun')"
                 style="width: 280px;margin-right: 10px;"/>
        <a-button class="search-btn" type="primary" @click="handleQuery">{{ $t('cha-xun') }}</a-button>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="circle">
            <cc-iconfont name="machine" size="16"/>
          </div>
          <div class="content">
            <div class="first">
              <div>{{ cluster.clusterDesc }}</div>
              <div>{{ cluster.clusterName }}</div>
            </div>
            <div class="second">
              <div>{{CLUSTER_TYPE[cluster.cloudOrIdcName]&&CLUSTER_TYPE[cluster.cloudOrIdcName].name}}</div>
              <div>{{getRegionName}}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <a-button v-if="myAuth.includes('DM_WORKER_MANAGE')" ghost icon="plus" style="margin-right: 10px;"
                    type="primary" @click="handleClickAddBtn">{{ $t('tian-jia-ji-qi') }}
          </a-button>
          <a-button class="refresh" @click="getWorkerList">{{ $t('shua-xin') }}</a-button>
        </div>
      </div>
    </div>
    <a-table :columns="workerListColumns" :data-source="workerList" :rowKey="record => record.id" size="small">
      <template slot="name" slot-scope="record">
        {{ record.workerName }}/{{ record.workerDesc }}
        <cc-iconfont :size="8" name="edit"
                     style="margin-right: 4px;" @click.native="handleClickEditWorkerNameBtn(record)"/>
        <a-popover placement="right">
          <cc-iconfont :size="12" name="detail"/>
          <div slot="content" class="worker-detail">
            <section>
              <div class="title">{{$t('gong-wang-xin-xi')}}:</div>
              <div>{{ record.publicIp }}</div>
            </section>
            <section>
              <div class="title">{{$t('di-qu')}}:</div>
              <div>{{ $t('bu-xian') }}</div>
            </section>
            <section>
              <div class="title">{{$t('chuang-jian-shi-jian')}}:</div>
              <div>{{ record.gmtCreate | formatTime('YYYY-MM-DD HH:mm:ss') }}</div>
            </section>
            <section>
              <div class="title">{{$t('ji-qi-lei-xing')}}:</div>
              <div>{{ $t('xu-ni-ji') }}</div>
            </section>
            <section>
              <div class="title">{{$t('ji-qi-wei-yi-biao-shi-fu')}}:</div>
              <div style="margin-right: 5px;">{{ record.workerSeqNumber }}</div>
              <cc-iconfont :size="12" name="copy" @click.native="copyText(`${record.workerSeqNumber}`, $t('fu-zhi-ji-qi-wei-yi-biao-shi-fu-cheng-gong'))"/>
            </section>
          </div>
        </a-popover>
        <cc-iconfont v-if="record.consoleTaskState==='FAILED'" @click.native="handleConsoleJob(record)" name="job-error"
                     color="#FF6E0D" size="16" style="margin-left: 6px"/>
      </template>
      <template slot="ip" slot-scope="record">
        {{ record.privateIp }}
      </template>
      <template slot="status" slot-scope="record">
        {{ '' }}
        <div v-if="record.deployStatus !== 'INSTALLING'&&record.deployStatus!=='UNINSTALLING'" style="display: flex">
          <cc-status :type="HEALTH_LEVEL_COLOR[record.healthLevel]"/>
          {{ WORKER_STATE[record.workerState].name }}
        </div>
        <div v-else-if="record.deployStatus === 'INSTALLING'">
          <div v-if="record.consoleTaskState === 'FAILED'" style="display:flex">
            <cc-status type="error"/>
            {{ $t('an-zhuang-shi-bai') }}
          </div>
          <div v-if="record.consoleTaskState !== 'FAILED'">
            <a-icon type="loading"/>
            {{ $t('an-zhuang-zhong') }}
          </div>
        </div>
        <div v-else-if="record.deployStatus === 'UNINSTALLING'">
          <div v-if="record.consoleTaskState === 'FAILED'" style="display:flex">
            <cc-status type="error"/>
            {{ $t('xie-zai-shi-bai') }}
          </div>
          <a-icon v-if="record.consoleTaskState !== 'FAILED'" type="loading"/>
          {{ $t('xie-zai-zhong') }}
        </div>
      </template>
      <template slot="actions" slot-scope="record">
        <div class="work-list-table-actions">
          <a-button :disabled="!canStart(record)" size="small"
                    type="link" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                    @click="handleAction('start', record)">{{ $t('qi-dong') }}
          </a-button>
          <a-button :disabled="!canStop(record)" size="small" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                    type="link"
                    @click="handleAction('stop', record)">{{ $t('ting-zhi') }}
          </a-button>
          <div v-if="record.cloudOrIdcName === CLUSTER_ENV.SELF_MAINTENANCE">
<!--            <a-button size="small" type="link"-->
<!--                      @click="handleAction('download', record)">-->
<!--              {{ $t('xia-zai-ke-hu-duan') }}-->
<!--            </a-button>-->
            <a-button size="small" type="link"
                      @click="handleAction('conf', record)">
              {{ $t('cha-kan-pei-zhi-wen-jian') }}
            </a-button>
          </div>
          <div v-else>
            <a-button :disabled="!canInstall(record)" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                      size="small" type="link"
                      @click="handleAction('install', record)">
              {{ $t('an-zhuang') }}
            </a-button>
            <a-button :disabled="!canUpdate(record)" size="small" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                      type="link"
                      @click="handleAction('update', record)">
              {{ $t('geng-xin') }}
            </a-button>
            <a-button :disabled="!canUninstall(record)" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                      size="small"
                      type="link"
                      @click="handleAction('uninstall', record)">{{ $t('xie-zai') }}
            </a-button>
          </div>
          <a-popconfirm :disabled="!canDelete(record)" v-if="myAuth.includes('DM_WORKER_MANAGE')"
                        cancel-text="取消" ok-text="确定"
                        :title="$t('que-ding-shan-chu-gai-ji-qi-ma')" @confirm="handleAction('delete', record)">
            <a-button :disabled="!canDelete(record)" size="small" type="link">{{ $t('shan-chu') }}</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-modal
      v-model="showAddWorkerModal"
      :mask-closable="false"
      :width="1240"
      :title="$t('tian-jia-ji-qi')"
    >
      <add-machine-modal v-if="showAddWorkerModal" :clusterId="parseInt(clusterId, 10)"
                         :handleAddWorker="handleAddWorker" :handleCancelAddWorker="handleCancelAddWorker"/>
    </a-modal>
    <a-modal v-model="showDownloadModal" :mask-closable="false" :width="620" :title="$t('xia-zai-ke-hu-duan')">
      <div class="download-modal">
        <a-alert banner :message="$t('xia-zai-lian-jie-1-xiao-shi-nei-you-xiao-qing-jin-kuai-xia-zai')" style="margin-bottom: 10px;"/>
        <div class="url">
          {{ downloadUrl }}
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="handleDownload">{{ $t('xia-zai') }}</a-button>
          <a-button type="primary" @click="copyText(downloadUrl, $t('fu-zhi-xia-zai-lian-jie-cheng-gong'))">{{ $t('fu-zhi-lian-jie') }}</a-button>
          <a-button @click="handleCancelDownload">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showConfigModal" :mask-closable="false" :width="630" :title="$t('pei-zhi-wen-jian')">
      <div v-if="showConfigModal" class="config-modal">
        <a-alert banner :message="$t('qing-jiang-xia-lie-pei-zhi-wan-zheng-fu-zhi-dao-clouddm-an-zhuang-mu-lu-xia-de-confglobalconfproperties-wen-jian-zhong')"
                 style="margin-bottom: 10px;"/>
        <div class="config">
          <div>{{ workerConfig.userAkLabel }}={{ workerConfig.userAkValue }}</div>
          <div>{{ workerConfig.userSkLabel }}={{ workerConfig.userSkValue }}</div>
          <div>{{ workerConfig.wsnLabel }}={{ workerConfig.wsnValue }}</div>
          <div>{{ workerConfig.consoleHostLabel }}={{ workerConfig.consoleHostValue }}</div>
          <div>{{ workerConfig.consolePortLabel }}={{ workerConfig.consolePortValue }}</div>
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="copyConfig">{{ $t('fu-zhi') }}</a-button>
          <a-button @click="showConfigModal=false">{{ $t('guan-bi') }}</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showEditWorkerNameModal" :mask-closable="false" :width="400" :cancelText="$t('qu-xiao')"
             :okText="$t('bao-cun')" :title="$t('xiu-gai-ming-cheng')" wrapClassName="have-footer" @ok="handleUpdateWorkerDesc">
      <a-input v-model="selectedWorker.workerDesc" type="textarea"/>
    </a-modal>
    <a-modal v-model="showGetConfigSms" :mask-closable="false" :width="370">
      <div class="sms-modal">
        <div>{{ $t('huo-qu-pei-zhi-wen-jian-xu-duan-xin-yan-zheng') }}</div>
        <div class="code">
          <a-input style="margin-right:6px" v-model="smsCode"/>
            <cc-sms-button login :verifyType="VERIFY_TYPE.SMS"
                           :verifyCodeType="VERIFY_CODE_TYPE.FETCH_WORKER_DEPLOY_CORE_CONFIG"/>
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="getWorkerConfig(selectedWorker)">{{ $t('cha-kan') }}</a-button>
          <a-button @click="hideSmsModal">{{ $t('guan-bi') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script lang="js">
import cloneDeep from 'lodash.clonedeep';
import AddMachineModal from '@/views/system/cluster/components/AddMachineModal';
// import { Modal } from 'view-design';
import { Modal } from 'ant-design-vue';
import {
  CLUSTER_ENV,
  CLUSTER_TYPE,
  DEPLOY_STATUS,
  HEALTH_LEVEL_COLOR,
  VERIFY_CODE_TYPE,
  VERIFY_TYPE,
  WORKER_STATE
} from '@/const';
import { mapState } from 'vuex';
import copyMixin from '@/mixins/copyMixin';

export default {
  name: 'WorkerList',
  components: {
    AddMachineModal
  },
  mixins: [copyMixin],
  data() {
    return {
      VERIFY_CODE_TYPE,
      VERIFY_TYPE,
      CLUSTER_TYPE,
      queryForm: { text: '' },
      cluster: {},
      DEPLOY_STATUS,
      WORKER_STATE,
      HEALTH_LEVEL_COLOR,
      CLUSTER_ENV,
      countDown: 60,
      codeBtnMsg: this.$t('huo-qu-yan-zheng-ma'),
      timer: null,
      smsCode: '',
      clusterId: '',
      downloadUrl: '',
      searchText: '',
      workerConfig: {
        consoleHostLabel: '',
        consoleHostValue: '',
        consolePortLabel: '',
        consolePortValue: '',
        userAkLabel: '',
        userAkValue: '',
        userSkLabel: '',
        userSkValue: '',
        wsnLabel: '',
        wsnValue: ''
      },
      showConfModal: false,
      showAddWorkerModal: false,
      showConfigModal: false,
      showDownloadModal: false,
      workerList: [],
      workerFilterInfo: {
        query: ''
      },
      selectedWorker: {},
      showEditWorkerNameModal: false,
      showGetConfigSms: false,
      regions: []
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      regionList: (state) => state.regionList,
      myAuth: (state) => state.myAuth
    }),
    getRegionName() {
      let regionName = '';
      this.regions.forEach((region) => {
        if (region.region === this.cluster.region) {
          regionName = region.i18nName;
        }
      });
      return regionName;
    },
    workerListColumns() {
      const { query } = this.workerFilterInfo;
      return [
        {
          title: this.$t('ji-qi-id-ming-cheng'),
          filteredValue: [query] || null,
          key: 'name',
          onFilter: (value, record) => record.workerDesc.includes(value) || record.workerName.includes(value),
          scopedSlots: { customRender: 'name' }
        }, {
          title: this.$t('ji-qi-ip'),
          scopedSlots: { customRender: 'ip' }
        }, {
          title: this.$t('zhuang-tai'),
          scopedSlots: { customRender: 'status' }
        }, {
          title: this.$t('fu-zai'),
          dataIndex: 'workerLoad',
          key: 'workerLoad'
        }, {
          title: this.$t('cpu-shi-yong-shuai'),
          dataIndex: 'cpuUseRatio',
          key: 'cpuUseRatio'
        }, {
          title: this.$t('nei-cun-shi-yong-shuai'),
          dataIndex: 'memUseRatio',
          key: 'memUseRatio'
        },
        {
          title: this.$t('yi-shi-yong-hui-hua-shu'),
          dataIndex: 'sessionPoolUse',
          key: 'sessionPoolUse'
        },
        {
          title: this.$t('zui-da-hui-hua-shu'),
          dataIndex: 'sessionPoolMax',
          key: 'sessionPoolMax'
        },
        {
          title: this.$t('cao-zuo'),
          width: 500,
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }];
    }
  },
  methods: {
    hideConfModal() {
      this.showConfModal = false;
    },
    listDmRegions() {
      this.$services.listRegions()
        .then((res) => {
          if (res.success) {
            this.regions = res.data;
          }
        });
    },
    async queryClusterById() {
      const res = await this.$services.getCluster({ data: { clusterId: this.clusterId } });
      if (res.success) {
        this.cluster = res.data;
      }
    },
    handleClickAddBtn() {
      this.showAddWorkerModal = true;
    },
    canStart(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && ((worker.deployStatus === 'INSTALLED' && worker.cloudOrIdcName !== 'SELF_MAINTENANCE') || worker.cloudOrIdcName === 'SELF_MAINTENANCE');
    },
    canStop(worker) {
      return worker.workerState === 'ONLINE' || worker.workerState === 'WAIT_TO_ONLINE' || worker.workerState === 'ABNORMAL';
    },
    canInstall(worker) {
      return (worker.workerState === 'OFFLINE'
        && (worker.deployStatus === 'UNINSTALLED' || worker.deployStatus === '')
        || ((worker.workerState === 'WAIT_TO_OFFLINE' && worker.deployStatus === 'UNINSTALLED')));
    },
    canUpdate(worker) {
      return worker.deployStatus === 'INSTALLED';
    },
    canUninstall(worker) {
      return (worker.workerState === 'WAIT_TO_OFFLINE' || worker.workerState === 'OFFLINE') && worker.deployStatus === 'INSTALLED';
    },
    canDelete(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && (worker.deployStatus === 'UNINSTALLED' && worker.cloudOrIdcName !== 'SELF_MAINTENANCE' || worker.cloudOrIdcName === 'SELF_MAINTENANCE')
        || (worker.cloudOrIdcName === 'SELF_MAINTENANCE' && !worker.privateIp) || worker.healthLevel !== 'Health';
    },
    handleClickEditWorkerNameBtn(worker) {
      this.selectedWorker = cloneDeep(worker);
      this.showEditWorkerNameModal = true;
    },
    async handleUpdateWorkerDesc() {
      const {
        id,
        workerDesc
      } = this.selectedWorker;
      const data = {
        desc: workerDesc,
        workerId: id
      };
      const res = await this.$services.updateWorkerDesc({
        data,
        msg: this.$t('xiu-gai-ji-qi-ming-cheng-cheng-gong')
      });

      if (res.success) {
        this.getWorkerList();
        this.showEditWorkerNameModal = false;
      }
    },
    copyConfig() {
      const {
        consoleHostLabel,
        consoleHostValue,
        consolePortLabel,
        consolePortValue,
        userAkLabel,
        userAkValue,
        userSkLabel,
        userSkValue,
        wsnLabel,
        wsnValue
      } = this.workerConfig;
      const value = `
      ${userAkLabel}=${userAkValue}\n
      ${userSkLabel}=${userSkValue}\n
      ${wsnLabel}=${wsnValue}\n
      ${consoleHostLabel}=${consoleHostValue}\n
      ${consolePortLabel}=${consolePortValue}
      `;
      this.copyText(value, this.$t('pei-zhi-wen-jian-fu-zhi-cheng-gong'));
    },
    handleQuery() {
      this.workerFilterInfo = {
        query: this.queryForm.text
      };
    },
    handleCancelDownload() {
      this.showDownloadModal = false;
    },
    async handleDownload() {
      window.open(this.downloadUrl);
    },
    async handleAddWorker(data = {}) {
      const res = await this.$services.createWorker({
        data: {
          ...data,
          clusterId: this.clusterId,
          region: 'customer'
        },
        msg: this.$t('sheng-cheng-ji-qi-biao-shi-fu-cheng-gong')
      });

      if (res.success) {
        this.showAddWorkerModal = false;
        await this.getWorkerList();
      }
    },
    async getWorkerList() {
      const data = {
        clusterId: this.clusterId
      };
      const res = await this.$services.getWorkerList({ data });
      if (res.success) {
        this.workerList = res.data;
      }
    },
    handleCancelAddWorker() {
      this.showAddWorkerModal = false;
      this.getWorkerList();
    },
    async handleAction(action, worker) {
      let res;
      const { id: workerId } = worker;
      const data = {
        clusterId: this.clusterId
      };
      if (['install', 'uninstall', 'update'].includes(action)) {
        data.workerIds = [workerId];
      } else {
        data.workerId = workerId;
      }
      switch (action) {
        case 'install':
          res = await this.$services.installAliyunEcs({
            data,
            msg: this.$t('ji-qi-an-zhuang-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'uninstall':
          this.workerList.forEach((w) => {
            if (w.id === workerId) {
              w.deployStatus = 'UNINSTALLING';
            }
          });
          this.workerList = [...this.workerList];
          res = await this.$services.uninstallAliyunEcs({
            data,
            msg: this.$t('ji-qi-xie-zai-cheng-gong')
          });
          // if (res.success) {
          //   this.getWorkerList();
          // }
          break;
        case 'update':
          res = await this.$services.upgradeAliyunEcs({
            data,
            msg: this.$t('ji-qi-geng-xin-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'start':
          res = await this.$services.startWorker({
            data,
            msg: this.$t('ji-qi-qi-dong-cheng-gong')
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'stop':
          res = await this.$services.stopWorker({
            data,
            msg: this.$t('ji-qi-ting-zhi-cheng-gong')
          });

          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'download':
          res = await this.$services.getDownloadUrl({ data });
          if (res.success) {
            this.downloadUrl = res.data;
            this.showDownloadModal = true;
          }
          break;
        case 'delete':
          res = await this.$services.deleteWorker({ data });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'conf':
          this.selectedWorker = data;
          this.showGetConfigSms = true;
          // Modal.confirm({
          //   title: this.$t('pei-zhi-wen-jian-yan-zheng'),
          //   width: 370,
          //   okText: this.$t('que-ding'),
          //   cancelText: this.$t('qu-xiao'),
          //   onOk: () => this.getWorkerConfig(data),
          //   onCancel: () => this.hideSmsModal(),
          //   content: () => (
          //     <div class="sms-modal">
          //       <div>{ this.$t('huo-qu-pei-zhi-wen-jian-xu-duan-xin-yan-zheng') }</div>
          //       <div class="code">
          //         <a-input style="margin-right:6px" v-model={this.smsCode}/>
          //         <cc-sms-button login verifyType={VERIFY_TYPE.SMS}
          //                        verifyCodeType={VERIFY_CODE_TYPE.FETCH_WORKER_DEPLOY_CORE_CONFIG}/>
          //       </div>
          //     </div>
          //   )
          // });
          break;
        default:
          return false;
      }
    },
    hideSmsModal() {
      this.showGetConfigSms = false;
      this.smsCode = '';
    },
    async getWorkerConfig(data = {}) {
      data.verifyCode = this.smsCode;
      const res = await this.$services.getWorkerConfig({ data });
      if (res.success) {
        Modal.destroyAll();
        this.workerConfig = res.data;
        this.showConfigModal = true;
        this.smsCode = '';
      }
    },
    handleConsoleJob(record) {
      this.$router.push({ path: `/system/console_job/${record.consoleJobId}` });
    }
  },
  created() {
    this.clusterId = this.$route.params.clusterId;
    this.queryClusterById();
    this.getWorkerList();
    this.listDmRegions();
  }
};
</script>

<style lang="less" scoped>
.machine-list {
  padding: 16px;

  .info {
    .bottom {
      margin-top: 10px;
      height: 70px;
      width: 100%;
      background-color: rgba(250, 250, 250, 1);
      border: 1px solid rgba(234, 234, 234, 1);
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      .left {
        display: flex;

        .circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(255, 193, 92, 1);
          box-shadow: 0px 3px 12px 0px rgba(255, 192, 92, 1);
          text-align: center;
          line-height: 40px;
          margin-left: 20px;
        }

        .content {
          display: flex;
          flex-direction: column;
          margin-left: 17px;

          & > div {
            display: flex;
          }

          .first {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            & > div:first-child {
              font-size: 14px;
              font-weight: bold;
              margin-right: 10px;
            }
          }

          .second {
            & > div {
              border-radius: 14px;
              padding: 0 5px;
              background-color: rgba(224, 224, 224, 1);

              &:first-child {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .header {
    margin-top: 20px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    .operation {
    }
  }

}

.ivu-poptip-body-content {
  .worker-detail {
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    padding: 10px 0;

    section {
      margin-left: 10px;
      width: 250px;
      display: flex;
      margin-bottom: 5px;

      .title {
        font-weight: bold;
      }
    }
  }
}

.download-modal {
  .url {
    padding: 10px;
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    word-wrap: break-word;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 10px;
    }
  }
}

.sms-modal {
  .code {
    display: flex;
    margin-top: 10px;
  }

  .btn-group {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 10px;
    }
  }
}

.config-modal {
  .config {
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    padding: 10px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 10px;
    }
  }
}

.work-list-table-actions {
  display: flex;
}

.worker-detail {
  display: flex;
  flex-wrap: wrap;
  width: 600px;

  section {
    display: flex;
    height: 20px;
    line-height: 20px;

    .title {
      color: #888;
    }

    div:last-child {
      font-weight: bold;
    }

    &:not(:last-child) {
      width: 50%;
    }
  }
}
</style>
